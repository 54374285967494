

































import Vue from "vue"
import { RtbControlButton } from "@/components/ui"
import SwapPhoneService from "@/services/swap-phone.service"
import AuthService from "@/services/auth.service"

export default Vue.extend({
  name: "SwapPhone",
  components: {
    RtbControlButton
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      qrCode: null
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    viewerTeam() {
      return this.$store.getters.chats[this.user?.teamID]
    },
    user() {
      return this.$store.getters["auth/user"]
    },
    gameName() {
      const game = this.$store.getters?.game

      return game?.externalName || game?.name
    }
  },
  watch: {
    async isOpen(value) {
      if (value) {
        const { url } = await SwapPhoneService.requestQR(() =>
          AuthService.signOut()
        )
        this.qrCode = url
      }
    }
  },
  methods: {
    close() {
      console.log("input", false)
      // @ts-expect-error Expect

      this.isOpen = false
    }
  }
})
