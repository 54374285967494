var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swap-phone", class: { "swap-phone--open": _vm.isOpen } },
    [
      _c(
        "RtbControlButton",
        {
          staticClass: "mobile-btn mobile-btn--top-right",
          on: { click: _vm.close },
        },
        [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("close")])],
        1
      ),
      _c(
        "h1",
        { staticClass: "swap-phone__title" },
        [
          _c("v-icon", { staticClass: "mr-2 mt-1", attrs: { dark: "" } }, [
            _vm._v("sync_alt"),
          ]),
          _vm._v("SWAP PHONES "),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "swap-phone__image" },
        [
          _vm.qrCode
            ? _c("img", { attrs: { src: _vm.qrCode } })
            : _c("v-progress-circular", {
                staticClass: "swap-phone__spinner",
                attrs: { indeterminate: "" },
              }),
        ],
        1
      ),
      _c("div", { staticClass: "swap-phone__text" }, [
        _vm.gameName
          ? _c("div", { staticClass: "swap-phone__game" }, [
              _vm._v(_vm._s(_vm.gameName)),
            ])
          : _vm._e(),
        _vm.viewerTeam
          ? _c(
              "div",
              { staticClass: "swap-phone__team" },
              [
                _c("v-icon", { staticClass: "mr-2", attrs: { dark: "" } }, [
                  _vm._v(_vm._s(_vm.viewerTeam.icon)),
                ]),
                _vm._v(" " + _vm._s(_vm.viewerTeam.name) + " "),
              ],
              1
            )
          : _vm._e(),
        _vm._m(0),
        _c("div", { staticClass: "swap-phone__warn" }, [
          _vm._v(
            " When the new phone joins the game, this phone will disconnect "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "swap-phone__inf" }, [
      _c("div", { staticClass: "swap-phone__inf-title" }, [
        _vm._v("Scan QR Code"),
      ]),
      _vm._v(
        " Open the camera app on the phone you would like to switch to and scan the QR code "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }